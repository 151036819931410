<template>
  <main>
    <Loading v-if="isLoading"></Loading>
    <div v-else>
      <h2 class="my-2 font-semibold text-blue-400 text-2xl page-title">
        Loans
      </h2>
      <div class="my-10 flex justify-between items-center">
        <input
          type="text"
          class="inline-block  px-2 ring-2 outline-none ring-blue-500 rounded-sm ml-2 w-64 text-sm py-2"
          name=""
          placeholder="Search"
          id=""
          v-model="search"
        />
        <button
          @click="
            $router.push({
              name: 'NewLoan',
            })
          "
          class="block uppercase shadow bg-blue-600  leading-6 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-8 rounded"
        >
          New Loan
        </button>
      </div>

      <div class="overflow-scroll pb-6">
        <table class="shadow-lg bg-white w-full">
          <thead>
            <tr>
              <th
                class="text-l p-3 text-left"
                colspan="13"
                style="background: #DEE6F2;"
              >
                Merchant Information
              </th>
            </tr>
            <tr>
              <th
                class="bg-blue-100  text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Merchant Name
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                TID
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Loan Value
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Paid To Date
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Daily Payment
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              ></th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(loan, i) in filterLoans"
              :key="i"
              class="hover:bg-gray-300"
            >
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ loan.business_name || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ loan.loan_item.terminal_id || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ loan.loan_item.initial_loan_amount }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ loan.loan_item.loan_amount_paid }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ loan.loan_item.loan_repayment_type.amount_or_percent_value }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ loan.terminal_provider_name }}
              </td>

              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                <button
                  @click="
                    $router.push({
                      name: 'EditLoan',
                      params: {
                        id: loan,
                      },
                    })
                  "
                  class="block uppercase shadow bg-green-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-8 rounded"
                >
                  Edit
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import Loading from "./general-components/Loading.vue";
export default {
  name: "Loans",
  data() {
    return {
      isLoading: false,
      search: "",
      moment,
    };
  },
  components: {
    Loading,
  },
  computed: {
    ...mapGetters(["GET_LOANS"]),
    filterLoans() {
      return this.GET_LOANS.data.item;
    },
  },
  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  methods: {
    ...mapActions(["FETCH_ALL_LOANS"]),
    async fetchAllLoans() {
      this.isLoading = true;
      try {
        let res = await this.$store.dispatch("FETCH_ALL_LOANS");
        this.isLoading = false;
      } catch (error) {
        // alert(error);
      }
    },
  },
  async created() {
    this.fetchAllLoans();
  },
};
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
